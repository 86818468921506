import React, {useEffect, useRef, useState} from "react";
import {TaskList} from "../../model/TaskList";
import {useHttp} from "../../hooks/http.hook";
import {beurl} from "../../constants/Constants";
import ConfirmationWindow from "../../forms/ConfirmationWindow";
import {PageHeader} from "../PageHeader";
import {TaskListAddEdit} from "./TaskListAddEdit";
import {UserBasic} from "../../model/UserBasic";
import {Button} from "../../forms/Button";
import {Sorter} from "../TaskComp/TasksTableComp";
import {AnimatePresence, motion} from "framer-motion";
import {ShrinkAnimation, SpringAnimation} from "../TaskComp/TaskAnimations";
import {nameAlias} from "../../model/User";
import {useSelector} from "react-redux";
import {AuthUser} from "../../reducers";
import {SimpleCell} from "../../forms/SimpleCell";
import {createPortal} from "react-dom";
import {checkAuthentication, logout} from "../../services/UserService";
import {useNavigate} from "react-router-dom";

export const TaskListComp = () => {

    /* Local constants */
    const request = useHttp();
    const beuri = beurl(window.location.host.split(':')[0]);
    const userUrl = beuri + 'api/current/contacts';
    const taskUrl = beuri + 'api/task/tasklist';

    /* Redux */
    const loggedInUser: UserBasic | null = (useSelector<AuthUser>(state => state.user) as AuthUser).user

    class TaskListData {
        [key: string]: any;

        name: string;
        participants: string;
        owner: string;
        id: number;

        constructor(name: string, participants: string, owner: string, id: number) {
            this.name = name;
            this.participants = participants;
            this.owner = owner;
            this.id = id;
        }
    }

    /* Local states */
    const [taskListList, setTaskListList] = useState<TaskList[]>([]);
    const [taskList, setTaskList] = useState<TaskList>(new TaskList());
    const [users, setUsers] = useState<UserBasic[]>([]);
    const [taskListToDelete, setTaskListToDelete] = useState(-1);
    const [showEditTaskList, setShowEditTaskList] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [sorter, setSorter] = useState<Sorter>({value: "id", ascending: true});
    const [sortableTable, setSortableTable] = useState<TaskListData[]>([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const navigate = useNavigate();

    /* Hooks */
    useEffect(() => {
        getUsers();
        getTaskLists();

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }

    }, []);

    useEffect(() => {
        const table: TaskListData[] = [];
        taskListList.forEach(row => {
            table.push(new TaskListData(
                row.name,
                row.participants.map(item => nameAlias(item)).sort().join(', '),
                nameAlias(row.owner),
                row.id ? row.id : 0
            ))
        })
        setSortableTable(table);
    }, [taskListList]);

    /* Testing */
    // useEffect(() => {
    //     console.log(sorter);
    // }, [sorter]);

    /* Functions */
    const getUsers = () => {
        // console.log("Test getUsers().");
        request(userUrl, "GET")
            .then(data => {
                // console.log(data);
                setUsers(data);
            })
            .catch((e) => {
                console.error(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            });
    }

    const getTaskLists = () => {
        request(taskUrl)
            .then(data => {
                // console.log(data);
                const taskLists: any[] = data;
                taskLists.forEach((item) => {
                    item.customFields = JSON.parse(item.customFields);
                    item.listParameters = JSON.parse(item.listParameters);
                })
                // console.log(taskLists);
                setTaskListList(taskLists);
            })
            .catch((e) => {
                console.error(e.message)
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            });
    }

    const updateTaskList = (taskList: TaskList) => {
        request(taskUrl, 'POST', JSON.stringify(taskList))
            .then(() => {
                getTaskLists();
                setTaskList(new TaskList());
                setShowEditTaskList(false);
            })
            .catch(e => {
                console.error(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
    }

    const deleteToDosList = () => {
        if (taskListToDelete > -1) {
            request(taskUrl + '/' + taskListToDelete, 'DELETE')
                .then(() => setTaskList(new TaskList()))
                .catch((e) => {
                    console.error('Failed to delete the task.');
                    if (e.cause && e.cause === 403) {
                        logout();
                        navigate("/expired");
                    }
                })
                .finally(() => getTaskLists());
        }
        setTaskListToDelete(-1);
        setEditMode(false);
        setShowEditTaskList(false);
    }

    const editTaskList = (taskListId: number) => {
        checkAuthentication()
            .then((_) => {
                setTaskList(taskListList.find(item => item.id === taskListId) as TaskList);
                setEditMode(true);
                setShowEditTaskList(true);
            })
            .catch(e => {
                console.error(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
    }

    /* Handlers */

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    }

    const handleCancel = () => {
        setShowEditTaskList(false);
        setTaskList(new TaskList());
    }

    const handleNewTask = () => {
        checkAuthentication()
            .then((_) => {
                setEditMode(false);
                setShowEditTaskList(true);
            })
            .catch(e => {
                console.error(e.message);
                logout();
                navigate("/expired");
            })
    }

    const handleSorting = (value: string, used: boolean) => {
        // console.log(`Sorter value is ${val}`);
        // console.log(`Sorter ascending is ${sorter.ascending}`);
        if (used) {
            if (value === sorter.value) {
                setSorter({...sorter, ascending: !sorter.ascending});
            } else {
                setSorter({value, ascending: true});
            }
        }
    }

    const sortRows = (a: TaskListData, b: TaskListData): number => {
        try {
            return ((a[sorter.value].toLowerCase() > b[sorter.value].toLowerCase()) ? (sorter.ascending ? 1 : -1)
                : (a[sorter.value].toLowerCase() > b[sorter.value].toLowerCase()) ? 0 : (sorter.ascending ? -1 : 1));
        } catch (e: any) {
            return ((a[sorter.value] > b[sorter.value]) ? (sorter.ascending ? 1 : -1)
                : (a[sorter.value] > b[sorter.value]) ? 0 : (sorter.ascending ? -1 : 1));
        }
    }


    const headerColumns = ["#", "Tasks list", "Participants", "Owner", ""];
    const headerValues = ["", "name", "participants", "owner", ""];
    const columnWidth = ["5%", "25%", "40%", "25%", "5%"];
    const columnMinWidth = ["20px", "80px", "100px", "80px", "30px"];

    return (
        <div className="fade-in">
            <PageHeader
                title={"Task Lists"}
                buttonUsed={true}
                onClick={handleNewTask}
                refreshUsed={true}
                onRefresh={getTaskLists}
            />
            <div
                // style={windowWidth > 900 ? {fontSize: "1rem"} : windowWidth < 300 ? {fontSize: "0.5rem"}
                //     : {fontSize: (((windowWidth - 600) / 600) * 0.3 + 0.7) + "rem"}}
                style={windowWidth > 900 ? {fontSize: "1rem"} : windowWidth < 300 ? {fontSize: "0.7rem"}
                    : {fontSize: (((windowWidth - 300) / 600) * 0.3 + 0.7) + "rem"}}
            >
                <div className="table-container" style={{minWidth: "350px"}}>
                    <div className="table-header">
                        {/*<AnimatePresence>*/}
                        {headerColumns.map((item, index) => {
                            return (
                                <motion.div
                                    {...SpringAnimation}
                                    key={index}
                                    style={{
                                        width: columnWidth[index],
                                        minWidth: columnMinWidth[index],
                                        cursor: "pointer"
                                    }}
                                    onClick={() => handleSorting(headerValues[index], !(index === 0 || index === 4))}
                                >
                                    {item}
                                    {sorter.value === headerValues[index] && sorter.ascending &&
                                        <i className="fa fa-arrow-circle-down"></i>}
                                    {sorter.value === headerValues[index] && !sorter.ascending &&
                                        <i className="fa fa-arrow-circle-up"></i>}
                                </motion.div>
                            )
                        })}
                        {/*</AnimatePresence>*/}
                    </div>
                    <div>
                        <AnimatePresence>
                            {sortableTable
                                .sort((a, b) => sortRows(a, b))
                                .map((item, index) => {
                                    return (
                                        <motion.div
                                            {...ShrinkAnimation}
                                            key={item.id}
                                            className="table-row table-row-bottom-line"
                                        >
                                            <SimpleCell style={{width: columnWidth[0], minWidth: columnMinWidth[0]}}
                                            >{(index + 1) + ""}</SimpleCell>
                                            <SimpleCell style={{width: columnWidth[1], minWidth: columnMinWidth[2]}}
                                            >{item.name}</SimpleCell>
                                            <SimpleCell style={{width: columnWidth[2], minWidth: columnMinWidth[2]}}
                                            >{item.participants}
                                            </SimpleCell>
                                            <SimpleCell style={{width: columnWidth[3], minWidth: columnMinWidth[3]}}
                                            >{item.owner}</SimpleCell>

                                            <div style={{width: columnWidth[4], minWidth: columnMinWidth[4]}}>
                                                <Button className="but but-primary but-sm"
                                                        onClick={() => editTaskList(item.id)}>
                                                    <i className="fa fa-pencil-square-o"></i>
                                                </Button>
                                            </div>
                                        </motion.div>
                                    )
                                })}
                            {/* Footer */}
                            <div className="table-footer">
                                {/*<div></div>*/}
                                {/*<div></div>*/}
                                {/*<div></div>*/}
                                {/*<div></div>*/}
                                {/*<div></div>*/}
                            </div>
                        </AnimatePresence>
                    </div>
                </div>
            </div>
            {/*<button onClick={() => {console.log(loggedInUserName)}}>test</button>*/}
            {createPortal(
                <TaskListAddEdit
                    showComponent={showEditTaskList}
                    saveHandler={updateTaskList}
                    cancelHandler={handleCancel}
                    deleteHandler={setTaskListToDelete}
                    taskList={taskList}
                    users={[...users, loggedInUser!]}
                    editMode={editMode}
                    loggedInUser={loggedInUser}
                />,
                document.getElementById('root') as HTMLElement)}
            <ConfirmationWindow
                header={"Confirmation"}
                body={`Do you really want to delete task list "${taskListList.find(item => item.id === taskListToDelete)?.name}".`}
                show={taskListToDelete > -1}
                onConfirm={deleteToDosList}
                onCancel={() => setTaskListToDelete(-1)}
            />
        </div>
    )
}