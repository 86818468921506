import React, {useEffect, useRef, useState} from "react";
import {nameAlias, User} from "../../model/User";
import {useHttp} from "../../hooks/http.hook";
import {beurl, Constants} from "../../constants/Constants";
import {InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {Button} from "../../forms/Button";
import {ChangePasswordComp} from "./ChangePasswordComp";
import {ChangeEmailComp} from "./ChangeEmailComp";
import {InviteUserComp} from "./InviteUserComp";
import {PageHeader} from "../PageHeader";
import ConfirmationWindow from "../../forms/ConfirmationWindow";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {userSlice} from "../../reducers";
import {AnimatePresence, motion} from "framer-motion";
import {SimpleCell} from "../../forms/SimpleCell";
import {ShrinkAnimation} from "../TaskComp/TaskAnimations";
import {checkAuthentication, logout} from "../../services/UserService";
import {createPortal} from "react-dom";
import {ChangeAccountToLocal} from "./ChangeAccountToLocal";

export const UserSettingsComp = () => {

    /* Local constants */
    const request = useHttp();
    const url = beurl(window.location.host.split(':')[0]) + "api/current/";
    const loginUrl = Constants.loginPage;

    /* Local states */
    const [localUser, setLocalUser] = useState(new User());
    const [editedUser, setEditedUser] = useState(new User());
    const [edit, setEdit] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [showChangeToLocal, setShowChangeToLocal] = useState(false);
    const [editPassword, setEditPassword] = useState(false);
    const [addContact, setAddContact] = useState(false);
    const [deleteAccConfirm, setDeleteAccConfirm] = useState(false);
    const [contactToRemove, setContactToRemove] = useState({index: 0, name: ""})
    const [inviteErrorMessage, setInviteErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    /* Local Refs */
    const startRef = useRef<HTMLInputElement>(null);

    /* Redux */
    const dispatch = useDispatch();
    const {setUser, removeUser} = userSlice.actions;

    const navigate = useNavigate();

    /* Hooks */
    useEffect(() => {
        getUserData();
        getUser();
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }

    }, []);

    useEffect(() => {
        if (edit && startRef && startRef.current) {
            // setTimeout(() => {
            startRef.current!.focus();
            // }, 200)
        }
    }, [edit]);

    /* Web requests */
    const getUserData = () => {
        request(url + "user")
            .then(data => {
                setLocalUser(data);
                setEditedUser(data);
                getUser();
            })
            .catch((e) => {
                console.log(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
    }

    const saveUserData = () => {
        request(url + "user", "PUT", JSON.stringify(editedUser))
            .then(() => {
                getUserData();
                setEdit(false);
            })
            .catch((e) => {
                console.log(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
    }

    const sendAddContact = (invitedUser: string) => {
        setInviteErrorMessage("");
        request(url + "contact", "POST", JSON.stringify(invitedUser))
            .then(() => {
                getUserData();
                setAddContact(false);
            })
            .catch((e) => {
                console.log(e.message);
                setInviteErrorMessage(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
    }

    const updatePassword = (password: string) => {
        request(url + "password", "POST", JSON.stringify(password))
            .then(() => {
                setEditPassword(false);
            })
            .catch((e) => {
                console.log(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
    }

    const onUpdated = () => {
        getUser();
        getUserData();
        setEditEmail(false);
        setShowChangeToLocal(false);
    }

    const deleteAccount = () => {
        request(url + "deleteaccount", "DELETE")
            .then(() => {
                dispatch(removeUser(""));
                navigate(loginUrl);
            })
            .catch((e) => {
                console.log(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
    }

    const removeContact = () => {
        request(url + "removecontact/" + contactToRemove.index, "DELETE")
            .catch((e) => {
                console.log(e.message)
                logout();
                navigate("/expired");
            })
            .finally(() => {
                    setContactToRemove({index: 0, name: ""});
                    getUserData()
                }
            );
    }

    const resendEmailConfirmation = () => {
        request(url + "resendemailconfirmation")
            .then(() => {
                setMessage("E-mail confirmation request has been sent");
            })
            .catch((e) => {
                setMessage("");
                console.log(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
    }

    const getUser = () => {
        checkAuthentication()
            .then(data => {
                // console.log(data);
                if (data) {
                    dispatch(setUser(data));
                }
            })
            .catch(e => {
                console.error(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
    }


    /* Handlers */

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    }

    const handleUserEdit = (key: string, value: any) => {
        const user: User = {...editedUser};
        user[key] = value;
        setEditedUser(user);
    }

    const handleCancel = () => {
        setEditedUser(localUser);
        setEdit(false);
    }

    const handleKeyUp = (key: string) => {
        if (key === "Enter") {
            saveUserData();
        }
        if (key === "Escape") {
            handleCancel();
        }
    }

    return (
        <div className="d-grid align-items-center fade-in">
            <PageHeader
                title={"User settings"}
                buttonUsed={false}
                onClick={() => {
                }}
                refreshUsed={true}
                onRefresh={getUserData}
            />
            <div className="user-settings"
                 // style={windowWidth > 900 ? {fontSize: "1rem"} : windowWidth < 300 ? {fontSize: "0.5rem"}
                 //     : {fontSize: (((windowWidth - 600) / 600) * 0.3 + 0.7) + "rem"}}
                 style={windowWidth > 900 ? {fontSize: "1rem"} : windowWidth < 300 ? {fontSize: "0.7rem"}
                     : {fontSize: (((windowWidth - 300) / 600) * 0.3 + 0.7) + "rem"}}
            >
                <div className="table-container">
                    <div style={{height: "1rem"}} className="table-header">
                    </div>
                    <div className="table-row table-row-bottom-line">
                        <SimpleCell style={{width: "40%", minWidth: "100px"}}
                        >Name</SimpleCell>
                        <SimpleCell style={{width: "60%", minWidth: "100px"}}
                        >{localUser.name}</SimpleCell>
                    </div>
                    <div className="table-row table-row-bottom-line">
                        <SimpleCell style={{width: "40%", minWidth: "100px"}}
                        >Alias</SimpleCell>
                        <div hidden={edit} style={{width: "60%", minWidth: "100px"}}>
                            <SimpleCell style={{}}
                            >{localUser.alias}</SimpleCell>
                        </div>
                        <div hidden={!edit} style={{height: "1.5rem", width: "60%", minWidth: "100px"}}
                             className="no-label-input">
                            <InputSelectField
                                value={editedUser.alias}
                                handleAction={(value: string) => handleUserEdit("alias", value)}
                                type={InputSelectType.input}
                                ref={startRef}
                                handleKeyAction={handleKeyUp}
                            />
                        </div>
                    </div>
                    <div className="table-row table-row-bottom-line">
                        <SimpleCell style={{width: "40%", minWidth: "100px"}}
                        >Description</SimpleCell>
                        <div hidden={edit} style={{width: "60%", minWidth: "100px"}}>
                            <SimpleCell style={{}}
                            >{localUser.description}</SimpleCell>
                        </div>
                        <div hidden={!edit} style={{height: "1.5rem", width: "60%", minWidth: "100px"}}
                             className="no-label-input">
                            <InputSelectField
                                value={editedUser.description}
                                handleAction={(value: string) => handleUserEdit("description", value)}
                                type={InputSelectType.input}
                                handleKeyAction={handleKeyUp}
                            />
                        </div>
                    </div>
                    <div className="table-row table-row-bottom-line">
                        <SimpleCell style={{width: "40%", minWidth: "100px"}}
                        >E-mail</SimpleCell>
                        <SimpleCell style={{width: "60%", minWidth: "100px"}}
                        >{localUser.email}</SimpleCell>
                    </div>
                    <div className="table-row table-row-bottom-line">
                        <SimpleCell style={{width: "40%", minWidth: "100px"}}
                        >E-mail notification</SimpleCell>
                        <div hidden={edit} style={{width: "60%", minWidth: "100px"}}>
                            <SimpleCell style={{}}
                            >{localUser.sendEmails ? "Yes" : "No"}</SimpleCell>
                        </div>
                        <div hidden={!edit} style={{height: "1.5rem", width: "60%", minWidth: "100px"}}
                             className="no-label-input">
                            <InputSelectField
                                value={editedUser.sendEmails}
                                handleAction={(value: boolean) => handleUserEdit("sendEmails", value)}
                                type={InputSelectType.checkbox}
                                handleKeyAction={handleKeyUp}

                            />
                        </div>
                    </div>
                    <div className="table-row table-row-bottom-line">
                        <SimpleCell style={{width: "40%", minWidth: "100px"}}
                        >Status</SimpleCell>
                        <SimpleCell style={{width: "60%", minWidth: "100px"}} className="d-flex">
                            <div className={"w-100" + ((localUser.status !== "Active") && " error-text")}>
                                {localUser.status}
                            </div>
                            {localUser.status === "E-mail confirmation" &&
                                <Button className="but but-sm me-2 but-extralight"
                                        onClick={resendEmailConfirmation}>
                                    <i className="fa fa-refresh" style={{color: "gray"}}/>
                                </Button>
                            }

                        </SimpleCell>
                    </div>
                    <AnimatePresence>
                        <div className="pb-2 pt-1 table-row-bottom-line">
                            {localUser.contacts && localUser.contacts.map((contact, index) => {
                                return (
                                    <div className="table-row" key={index}>
                                        <SimpleCell style={{width: "40%", minWidth: "100px"}}
                                        >{index === 0 ? "Contacts" : ""}</SimpleCell>
                                        <SimpleCell
                                            style={{width: "60%", minWidth: "100px", paddingBottom: "0px"}}
                                            className="d-flex"
                                        >
                                            <div
                                                className={"w-100 d-flex table-row-bottom-line" + (index % 2 === 1 ? " task-even" : "")}>
                                                <div
                                                    className="w-100">
                                                    {(index + 1) + ". " + (contact.alias !== "" ? (contact.name + " / " + contact.alias) : contact.name)}
                                                </div>
                                                <div className="center-vertically">
                                                    <Button className="but but-rect me-2 but-extralight"
                                                            onClick={() =>
                                                                setContactToRemove(contact.id ? {
                                                                        index: contact.id,
                                                                        name: nameAlias(contact)
                                                                    } :
                                                                    {index: 0, name: ""})}
                                                    >
                                                        <i className="fa fa-trash-o"/>
                                                    </Button>
                                                </div>
                                            </div>
                                        </SimpleCell>
                                    </div>
                                )
                            })}
                        </div>
                        {message !== "" &&
                            <motion.div
                                {...ShrinkAnimation}
                                className="center-horizontally message-text"
                            >
                                {message}
                            </motion.div>
                        }
                    </AnimatePresence>
                    <div className="table-footer" style={{height: "1rem"}}>
                    </div>
                </div>
                <div className="d-flex gap-2 center-all mt-2">
                    <div hidden={edit}>
                        <Button className="but but-primary but-sm"
                                onClick={() => {
                                    setTimeout(() => {
                                        setEdit(true);
                                    }, 200)
                                }}
                                style={{width: "150px"}}>Edit</Button>
                    </div>
                    <div hidden={!edit}>
                        <Button className="but but-primary but-sm" onClick={saveUserData}
                                style={{width: "70px"}}>Save</Button>
                    </div>
                    <div hidden={!edit}>
                        <Button className="but but-warning but-sm" onClick={() => handleCancel()}
                                style={{width: "70px"}}>Cancel</Button>
                    </div>
                </div>
                {localUser.provider === "Local" &&
                    <div>
                        <div className="center-horizontally mt-3">
                            <Button className="but but-sm but-light" onClick={() => setEditEmail(true)}
                                    style={{width: "150px"}}>
                                Change e-mail
                            </Button>
                        </div>
                        <div className="center-horizontally mt-3">
                            <Button className="but but-sm but-light" onClick={() => setEditPassword(true)}
                                    style={{width: "150px"}}>
                                Change password
                            </Button>
                        </div>
                    </div>
                }
                <div>
                    <div className="center-horizontally mt-3">
                        <Button className="but but-sm but-success" onClick={() => setAddContact(true)}
                                style={{width: "150px"}}>Add contact</Button>
                    </div>
                    {(localUser.provider === "Google" || localUser.provider === "Facebook") &&
                        <div className="center-horizontally mt-3">
                            <Button className="but but-sm but-light" onClick={() => setShowChangeToLocal(true)}
                                    style={{width: "150px"}}>Change account type</Button>
                        </div>
                    }
                    <div className="center-horizontally mt-3">
                        <Button className="but but-sm but-danger" onClick={() => setDeleteAccConfirm(true)}
                                style={{width: "150px"}}>Delete
                            account</Button>
                    </div>
                </div>
            </div>
            {editPassword &&
                <ChangePasswordComp updatePassword={updatePassword} onCancel={() => setEditPassword(false)}/>
            }
            {editEmail &&
                createPortal(
                    <ChangeEmailComp onSuccess={onUpdated} onCancel={() => setEditEmail(false)}/>,
                    document.getElementById('root') as HTMLElement)
            }
            {showChangeToLocal &&
                createPortal(
                    <ChangeAccountToLocal onSuccess={onUpdated} onCancel={() => setShowChangeToLocal(false)}/>,
                    document.getElementById('root') as HTMLElement)
            }
            {addContact &&
                <InviteUserComp sendInvitation={sendAddContact}
                                onCancel={() => {
                                    setAddContact(false);
                                    setInviteErrorMessage("");
                                }}
                                errorMessage={inviteErrorMessage}/>
            }
            {deleteAccConfirm &&
                <ConfirmationWindow
                    header={"Confirm account deletion"}
                    body={"This operation will remove your account and all related data including Task Lists owned by you."}
                    show={deleteAccConfirm}
                    onCancel={() => setDeleteAccConfirm(false)}
                    onConfirm={deleteAccount}
                />
            }
            {contactToRemove.index !== 0 &&
                <ConfirmationWindow
                    header={"Confirm contact removal"}
                    body={`Are you sure you want to remove contact ${contactToRemove.name}?`}
                    show={contactToRemove.index !== 0}
                    onCancel={() => setContactToRemove({index: 0, name: ""})}
                    onConfirm={removeContact}
                />
            }
            {/*<Button onClick={() => console.log(localUser)}>Test</Button>*/}
        </div>
    )
}