import {CSSProperties, useEffect, useRef, useState} from "react";
import {convertToRaw} from "draft-js";
import {marked} from "marked";
import {getEditorContent} from "../utilities/EditorUtilities";

export const CollapsibleRow2 = (props: CollapsibleRowProps) => {

    const {
        children, index, onClick,
        showIndex, style, className, textClassName,
        showAll
    } = props;

    const [description, setDescription] = useState("");

    const cellRef = useRef(null);

    useEffect(() => {
        if (index === showIndex || showAll) {
            adjustHeight();
        } else {
            if (cellRef.current) {
                (cellRef.current as HTMLDivElement).style.maxHeight = '0';
            }
        }
    }, [showIndex, showAll, description]);

    useEffect(() => {
        setDescription(convertContentToMarkdown(children));
    }, [children]);

    const adjustHeight = () => {
        if (cellRef?.current) {
            (cellRef.current as HTMLDivElement).style.height = `${(cellRef.current as HTMLDivElement).scrollHeight + 20}px`;
            (cellRef.current as HTMLDivElement).style.maxHeight = `${(cellRef.current as HTMLDivElement).children[0].scrollHeight + 20}px`;
        }
    };

    // Function to convert content to Markdown
    const convertContentToMarkdown = (text: string): string => {
        const rawContent = convertToRaw(getEditorContent(text));
        let markdownString = '';
        rawContent.blocks.forEach((block) => {
            markdownString += block.text + '\n';
        });
        return marked(markdownString) as string;
    };


    return (
        <div className={className} style={style} onClick={(e) => (onClick ? onClick(e) : null)}>
            <div style={{height: "3px"}}
                 className={(index % 2 === 1 ? " task-even" : "")}
            ></div>
            <div ref={cellRef}
                 className={"task-description"}
            >
                <div className={"md-output " + textClassName}>
                    <div dangerouslySetInnerHTML={{__html: description}}></div>
                </div>
            </div>
            <div style={{height: "3px"}}
                 className={(index % 2 === 1 ? " task-even" : "")}
            ></div>
        </div>
    )
}

class CollapsibleRowProps {
    children: any;
    index: number;
    showIndex: number;
    showAll: boolean;
    style?: CSSProperties | undefined;
    className?: string;
    textClassName?: string;
    onClick?: Function;
}