import {TaskColumnSettings} from "../components/TaskComp/TaskColumnSettings";

export class TaskListUserSettings {
    // id: number | null;
    taskListId: number | null | undefined;
    settings: Map<string, TaskColumnSettings> = new Map<string, TaskColumnSettings>();
}

export interface TaskListUserSettingsItem {
    type: TaskListUserSettingsType;
    settings: Map<string, TaskColumnSettings>;
}

export interface UnparsedUserSettingsItem {
    type: TaskListUserSettingsType;
    settings: string;
}

export interface TaskListUserSettingsGroup {
    id: number | null;
    taskListId: number | null | undefined;
    type: TaskListUserSettingsType;
    items: TaskListUserSettingsItem[];
}

export enum TaskListUserSettingsType {
    group = "group",
    portrait = "portrait",
    landscape = "landscape"
}