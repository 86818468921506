import {DataOptionsUnit, InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {Align, TaskColumnSettings} from "./TaskColumnSettings";
import {Button} from "../../forms/Button";
import {
    TaskListUserSettings,
    TaskListUserSettingsGroup,
    TaskListUserSettingsItem
} from "../../model/TaskListUserSettings";
import {AnimatePresence, motion, PanInfo, useAnimation} from "framer-motion";
import React, {useRef, useState} from "react";
import * as Yup from 'yup';
import {OpacityAnimation} from "./TaskAnimations";
import '../switch.scss';

declare module 'yup' {
    interface StringSchema {
        isCssUnit(message: string): StringSchema;
    }
}
export const TaskColumnsSettingsComp = (props: TaskColumnsSettingsCompProps) => {

    const {
        taskListUserSettings, saveSettings, portrait, setPortrait, taskListUserSettingsGroup,
        handleTaskListUserSettings, setTaskListUserSettings, deleteSettings
    } = props;

    const settingsRowHeight: string = "1.8rem";

    // Animation controls to start the animation
    const controls = useAnimation();


    /* Local states */
    const [draggable, setDraggable] = useState<"x" | "y" | boolean | undefined>(false)
    const [validationMap, setValidationMap] = useState<Map<string, boolean>>(new Map());
    const [errorMessage, setErrorMessage] = useState("Width should be in CSS format.");

    /* Local references */
    const tableRef = useRef(null);
    const cellRef = useRef<HTMLDivElement>(null);

    /* Hooks */
    //

    const moveLeft = (index: number, key: string) => {
        const settings = [...taskListUserSettings.settings.entries()];
        const idx2 = settings.findIndex(item => {
            return item[1].columnPosition === (index - 1);
        });
        const key2 = [...settings][idx2] ? [...settings][idx2][0] : null;
        if (key2) {
            handleTaskListUserSettings(key2, 'columnPosition', index);
        }
        const idx = (index > 0) ? index - 1 : 0;
        handleTaskListUserSettings(key, 'columnPosition', idx);
    }

    const moveRight = (index: number, key: string) => {
        // console.log(`Current index is ${index} and key is ${key}.`);
        const settings = [...taskListUserSettings.settings.entries()];
        const idx2 = settings.findIndex(item => {
            return item[1].columnPosition === (index + 1);
        });
        // console.log(idx2);
        const key2 = [...settings][idx2] ? [...settings][idx2][0] : null;
        if (key2) {
            handleTaskListUserSettings(key2, 'columnPosition', index);
        }
        const idx = (index < settings.length - 2) ? index + 1 : settings.length - 1;
        handleTaskListUserSettings(key, 'columnPosition', idx);
    }

    const handleDragEnd = (e: MouseEvent | PointerEvent | TouchEvent, info: PanInfo, index: number) => {
        // console.log(`Drag ended at x=${info.point.x} and y=${info.point.y}.`);
        const width = cellRef.current ? cellRef.current.offsetWidth : 90;
        if (tableRef && tableRef.current) {
            const settings = new Map(taskListUserSettings.settings);
            const newIndex = index + Math.round(info.offset.x / width)
            // console.log(newIndex);
            const columns = [...settings.keys()].sort((a, b) => {
                if (settings && settings.get(a) && settings.get(b)) {
                    // @ts-ignore
                    return (settings.get(a).columnPosition > settings.get(b).columnPosition ? 1 :
                        // @ts-ignore
                        settings.get(a).columnPosition === settings.get(b).columnPosition ? 0 : -1);
                } else {
                    return 0;
                }
            })
            const movedItem = columns[index];
            columns.splice(index, 1);
            columns.splice(newIndex, 0, movedItem);
            // console.log(columns);
            columns.forEach((item, idx) => {
                const colSet: TaskColumnSettings = {...settings.get(item)!};
                colSet.columnPosition = idx;
                settings.set(item, colSet);
            })
            setTaskListUserSettings({...taskListUserSettings, settings});
            controls.start({
                x: 0,
                y: 0,
                transition: {type: 'spring', stiffness: 300, damping: 25},
            });
        }
    }

    /* Validation */
    Yup.addMethod(Yup.string, 'isCssUnit', function (this: Yup.StringSchema, message) {
        return this.test('isCssUnit', message, function (value) {
            const {path, createError} = this;

            const pixelRegex = /^\d+(px|%|rem|em|in|pc|pt)$/;
            if (value && pixelRegex.test(value)) {
                return true;
            }
            // console.log("Validation failed.");

            return createError({path, message});

        });
    });

    const schema = Yup.object().shape({
        myString: Yup.string().isCssUnit("Give number of pixels, e. g. '100px'.")
    });

    const handleWidth = (column: string, property: string, value: string) => {
        handleTaskListUserSettings(column, property, value);
        // console.log("minWidth changed.");
        const valMap = new Map(validationMap);
        schema.validate({myString: value})
            .then(() => {
                valMap.set(column + property, true);
                // console.log("Validation passed.");
            })
            .catch(() => {
                valMap.set(column + property, false);
                // console.error("Validation failed.");
            })
            .finally(() => {
                setValidationMap(valMap);
            })
    }

    return (
        <div className="collapsible-style">
            <div className="border-gray position-relative">
                <div className="collapsible-x-scroll pb-2">
                    <div className="d-flex column-settings" ref={tableRef}>

                        {/* Left title column */}
                        <div className="fw-bold center-vertically-div">
                            {
                                ['Header', 'Show', 'Title', 'Align', 'Width', 'MinWidth'].map((text, index) => {
                                    return (
                                        <div key={index}
                                             style={{height: settingsRowHeight, minWidth: "85px", marginTop: "0.05rem"}}
                                             className="justify-content-end pe-2"
                                        >
                                            {text}
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {/*<AnimatePresence>*/}
                        {/* User settings columns */}
                        {[...taskListUserSettings.settings.entries()]
                            .sort((a, b) => {
                                return a[1].columnPosition - b[1].columnPosition;
                            })
                            .map((item, index) => {
                                // console.log(`Setting id is ${item[1].id} and name is ${item[1].title}`);
                                return (
                                    <motion.div
                                        layout={true}
                                        animate={controls}
                                        drag={draggable}
                                        onDragEnd={(event, info) => handleDragEnd(event, info, index)}
                                        key={item[1].id}
                                        // key={index}
                                        style={{minWidth: "90px"}}
                                        className="center-vertically-div"
                                        id={item[1].columnPosition + ""}
                                        role={item[0]}
                                        ref={cellRef}
                                    >
                                        <div style={{height: settingsRowHeight}} className="fw-bold">
                                            {item[1].title}
                                        </div>
                                        <div style={{height: settingsRowHeight}} className="gap-2">
                                            <InputSelectField
                                                value={item[1].visible}
                                                handleAction={(value: boolean) => handleTaskListUserSettings(item[0], 'visible', value)}
                                                type={InputSelectType.checkbox}
                                                className="checkbox-custom"
                                            />
                                            <button className="but but-extralight but-rect"
                                                    style={{width: '4rem'}}
                                                    onMouseEnter={() => setDraggable("x")}
                                                    onMouseLeave={() => {
                                                        setDraggable(false)
                                                    }}>
                                                <i className="fa fa-exchange" style={{scale: "1.5"}}></i>
                                            </button>
                                        </div>
                                        <div style={{height: settingsRowHeight}} className="">
                                            <InputSelectField
                                                value={item[1].title}
                                                handleAction={(value: string) => handleTaskListUserSettings(item[0], 'title', value)}
                                                type={InputSelectType.input}
                                            />
                                        </div>
                                        <div style={{height: settingsRowHeight}} className="">
                                            <InputSelectField
                                                className="w-100"
                                                value={{
                                                    value: item[1].align.toString(),
                                                    label: item[1].align.toString()
                                                }}
                                                handleAction={(value: DataOptionsUnit) =>
                                                    handleTaskListUserSettings(item[0], 'align', value.value as Align)}
                                                optionsArray={Object.values(Align).map((item => item.toString()))}
                                                type={InputSelectType.select}
                                            />
                                        </div>
                                        <div style={{height: settingsRowHeight}} className="">
                                            <InputSelectField
                                                value={item[1].width}
                                                handleAction={(value: string) => handleWidth(item[0], 'width', value)}
                                                type={InputSelectType.input}
                                                error={validationMap.has(item[0] + 'width') && !validationMap.get(item[0] + 'width')}
                                            />
                                        </div>
                                        <div style={{height: settingsRowHeight}} className="">
                                            <InputSelectField
                                                value={item[1].minWidth}
                                                handleAction={(value: string) => handleWidth(item[0], 'minWidth', value)}
                                                type={InputSelectType.input}
                                                error={validationMap.has(item[0] + 'minWidth') && !validationMap.get(item[0] + 'minWidth')}
                                            />
                                        </div>
                                        {/*<div className="center-horizontally" style={{paddingTop: "8px"}}>*/}
                                        {/*    <Button*/}
                                        {/*        className="but but-rect but-extralight"*/}
                                        {/*        disabled={item[1].columnPosition <= 0}*/}
                                        {/*        onClick={() => moveLeft(index, item[0])}*/}
                                        {/*    >*/}
                                        {/*        &lt;*/}
                                        {/*    </Button>*/}
                                        {/*    <Button*/}
                                        {/*        className="but but-rect but-extralight"*/}
                                        {/*        disabled={item[1].columnPosition >= taskListUserSettings.settings.size - 1}*/}
                                        {/*        onClick={() => moveRight(index, item[0])}*/}
                                        {/*    >*/}
                                        {/*        &gt;*/}
                                        {/*    </Button>*/}
                                        {/*</div>*/}
                                    </motion.div>
                                )
                            })
                        }
                        {/*</AnimatePresence>*/}
                    </div>
                </div>
                <div className="center-horizontally gap-2 overflow-scrol shrink-container">
                    {/*<div className="d-flex gap-1">*/}
                    {/*    <div className="text-end selector-container">*/}
                    {/*        <span className={`selector-desc${portrait ? "" : " selector-desc-active"}`}>Landscape</span>*/}
                    {/*    </div>*/}
                    {/*    <div className="selector-container">*/}
                    {/*        <label className="switch-selector">*/}
                    {/*            <input*/}
                    {/*                type="checkbox"*/}
                    {/*                onChange={() => setPortrait(!portrait)}*/}
                    {/*                checked={portrait}*/}
                    {/*            />*/}
                    {/*            <span className="slider round"></span>*/}
                    {/*        </label>*/}
                    {/*    </div>*/}
                    {/*    <div className="text-start selector-container">*/}
                    {/*        <span className={`selector-desc${portrait ? " selector-desc-active" : ""}`}>Portrait</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <Button
                        disabled={![...validationMap.values()].every((item) => item)}
                        className="but but-primary"
                        onClick={saveSettings}
                    >Save settings</Button>
                    <Button
                        // disabled={![...validationMap.values()].every((item) => item)}
                        className="but but-warning"
                        onClick={deleteSettings}
                    >Delete settings</Button>
                    {/*<Button*/}
                    {/*    // disabled={![...validationMap.values()].every((item) => item)}*/}
                    {/*    className="but but-success"*/}
                    {/*    onClick={() => {*/}
                    {/*        console.log(taskListUserSettingsGroup);*/}
                    {/*        console.log(taskListUserSettings);*/}
                    {/*    }*/}
                    {/*    }*/}
                    {/*    // onClick={() => console.log(taskListUserSettingsGroup)}*/}
                    {/*>Test</Button>*/}
                    {/*<Button*/}
                    {/*    className="but but-primary but-sm" style={{width: "150px"}}*/}
                    {/*    // onClick={() => console.log(taskListUserSettings)}*/}
                    {/*>Test</Button>*/}
                </div>
                <AnimatePresence>
                    {![...validationMap.values()].every((item) => item) &&
                        <motion.div
                            {...OpacityAnimation}
                            className="error-message"
                        >
                            {errorMessage}
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
        </div>
    )
}


interface TaskColumnsSettingsCompProps {
    handleTaskListUserSettings: Function;
    saveSettings: Function;
    taskListUserSettings: TaskListUserSettingsItem;
    setTaskListUserSettings: Function;
    deleteSettings: Function;
    portrait: boolean;
    setPortrait: Function;
    taskListUserSettingsGroup: TaskListUserSettingsGroup;
    setTaskListUserSettingsGroup: Function;
}