import React, {useEffect, useState} from 'react';
import './App.css';
import './styles/global.scss'
import {TaskComp} from "./components/TaskComp/TaskComp";
import {Route, Routes} from "react-router-dom";
import NavBar from "./components/NavBar";
import {LoginComp} from "./components/Login/LoginComp";
import {SignUpComp} from "./components/SignUpComp";
import {HomePage} from "./components/HomePage";
import {TaskListComp} from "./components/TaskListComp/TaskListComp";
import {enableMapSet} from "immer";
import {EmailConfirmationComp} from "./components/Login/EmailConfirmationComp";
import {PasswordResetComp} from "./components/Login/PasswordResetComp";
import {PasswordNewComp} from "./components/Login/PasswordNewComp";
import {UserSettingsComp} from "./components/UserSettings/UserSettingsComp";
import {ChangeTypeComp} from "./components/UserSettings/ChangeTypeComp";
import {PolicyAndServicePage} from "./components/Login/PolicyAndServicePage";
import {DashboardComp} from "./components/DashboardComp";
import {ExpiredLogin} from "./components/ExpiredLogin";

const App = () => {

    enableMapSet();

    const [theme, setTheme] = useState<string>(() => {
        // Check localStorage for theme preference
        const savedTheme = localStorage.getItem('app-theme');
        return savedTheme || 'light';
    });

    useEffect(() => {
        // Apply theme class to body
        document.body.className = '';
        document.body.classList.add(`theme-${theme}`);

        // Save theme preference to localStorage
        localStorage.setItem('app-theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    return (
        <div className="m-auto ps-2 pe-2" style={{minWidth: "300px", maxWidth: "1200px"}}>
            <NavBar theme={theme} toggleTheme={toggleTheme}/>
            <div style={{marginBottom: "10rem"}}>
                <Routes>
                    <Route path="/dashboard" element={<DashboardComp/>}/>
                    <Route path="/tasks" element={<TaskComp/>}/>
                    <Route path="/tasklists" element={<TaskListComp/>}/>
                    <Route path="/settings" element={<UserSettingsComp/>}/>
                    <Route path="/login" element={<LoginComp/>}/>
                    <Route path="/signup" element={<SignUpComp/>}/>
                    <Route path="/policy" element={<PolicyAndServicePage/>}/>
                    <Route path="/changetype" element={<ChangeTypeComp/>}/>
                    <Route path="/confirmation/:key" element={<EmailConfirmationComp/>}/>
                    <Route path="/passwordreset" element={<PasswordResetComp/>}/>
                    <Route path="/newpassword/:key" element={<PasswordNewComp/>}/>
                    <Route path="/expired" element={<ExpiredLogin/>}/>
                    <Route path="/" element={<HomePage/>}/>
                </Routes>
            </div>
        </div>
    )
}

export default App;
