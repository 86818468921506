import {TaskListUserSettingsItem} from "../../model/TaskListUserSettings";
import {CustomField, TaskList} from "../../model/TaskList";
import {Align, StandardColumns, TaskColumnSettings} from "./TaskColumnSettings";

export const customFieldsInUserSettings = (userSettings: TaskListUserSettingsItem, selectedTaskList: TaskList | null | undefined,)
    : { usrSettings: TaskListUserSettingsItem } => {

    const customFields: CustomField[] = selectedTaskList ? selectedTaskList?.customFields : [];
    const settings = new Map<string, TaskColumnSettings>();
    Object.keys(StandardColumns).forEach((item, index) => {
        settings.set(item, userSettings.settings.has(item) ? userSettings.settings.get(item)!
            : defaultCustomColumnSettings(item, settings.size, index + 1));
    });

    /* Add custom fields */
    customFields?.forEach((item, index) => {
        settings.set(item.name, userSettings.settings.has(item.name) ? userSettings.settings.get(item.name)!
            : defaultCustomColumnSettings(item.name, settings.size, (100 + index)));
    });
    const usrSettings = {...userSettings, settings};
    // usrSettings.settings = settings;
    return {usrSettings};
}

export const defaultCustomColumnSettings = (name: string, columnPosition: number, id: number) => {
    return new TaskColumnSettings(id, name, false, Align.left, "10%", "50px", columnPosition);
}