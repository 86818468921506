import Select from "react-select";
import makeAnimated from 'react-select/animated';
import {DateTimePicker} from "react-datetime-picker";
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './DateTimePickerCustomStyle.scss';
import {ChangeEvent, ForwardedRef, forwardRef, MutableRefObject, useEffect, useRef, useState} from "react";
import "./formstyles.scss";

export const InputSelectField = forwardRef((props: InputSelectFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const errorBorder = {outline: "1px ridge rgba(255,0,0,0.7)", borderColor: "red"};
    const errorStyle = {color: 'red'};
    // const errorBorder = {border: "2px solid rgba(255, 0, 0, 0.3)", outline: "2px solid red"};
    const {
        value, placeholder, label, handleAction, index, error,
        handleKeyAction, disable, hidden, type,
        isClearable, isSearchable, optionsArray, backgroundcolor,
        isMulti, format, className, id
    } = props;

    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const [textAreaVisible, setTextAreaVisible] = useState(false);

    const customStyles = {
        placeholder: (provided: any) => ({
            ...provided,
            whiteSpace: 'nowrap', // Prevent wrapping
            overflow: 'hidden',   // Hide overflowed text
            textOverflow: 'ellipsis', // Add ellipsis for overflowed text
        }),
    }
    useEffect(() => {
        if (type === 'textarea') {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    setTextAreaVisible(entry.isIntersecting);
                },
                {threshold: 1.0}
            )
            if (textAreaRef.current) {
                observer.observe(textAreaRef.current);
            }
            return () => {
                if (textAreaRef.current) {
                    observer.unobserve(textAreaRef.current);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (textAreaVisible) {
            adjustHeight();
        }
    }, [textAreaVisible]);

    const handleTextArea = (event: ChangeEvent<HTMLTextAreaElement>, index?: number) => {
        handleAction(event.target.value, index);
        adjustHeight();
    };

    const adjustHeight = () => {
        if (textAreaRef?.current) {
            (textAreaRef.current as HTMLTextAreaElement).style.height = 'auto'; // Reset height to auto before calculating the scrollHeight
            (textAreaRef.current as HTMLTextAreaElement).style.height = `${(textAreaRef.current as HTMLTextAreaElement).scrollHeight}px`;
        }
    };

    const setRefs = (element: HTMLTextAreaElement) => {
        if (ref) {
            // @ts-ignore
            ref.current = element;
        }
        textAreaRef.current = element;
    }

    switch (type) {

        case 'input':
        case 'password':
        case 'number':
            return (
                <div hidden={hidden} className="input-wrapper">
                    <div className="input-field">
                        <label htmlFor={label} className="textlabel"
                               // style={{backgroundColor: backgroundcolor, color: error ? 'red' : 'black'}}
                        >{label}</label>
                        <input
                            ref={ref}
                            id={id}
                            className={'input-text' + (className ? ` ${className}` : '')}
                            type={type === "input" ? "text" : type === "number" ? "number" : "password"}
                            style={error ? errorBorder : {}}
                            placeholder={placeholder}
                            value={value}
                            onChange={(e) => handleAction(e.target.value, index)}
                            disabled={disable}
                            onKeyUp={(e) => {
                                e.preventDefault();
                                if (handleKeyAction) {
                                    handleKeyAction(e.key);
                                }
                            }}
                        />
                        {isClearable ?
                            <button className="cross-button" onClick={() => handleAction("", index)}>
                                <i className="fa fa-times"></i>
                            </button>
                            : null}
                    </div>
                </div>
            )
        case 'select':
            return (
                <div hidden={hidden} className={"input-wrapper " + className}>
                    <Select
                        // ref={ref}
                        classNamePrefix="select"
                        styles={customStyles}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        placeholder={placeholder}
                        value={value}
                        onChange={(newValue: any) => handleAction(newValue ? newValue : "", index)}
                        options={dataOptions(optionsArray)}
                        id={id}
                        isMulti={isMulti}
                        components={makeAnimated()}
                        isDisabled={disable}
                        // classNames={{
                        //     container: () => 'select-container'
                        //}}
                    />
                    <label htmlFor={label} className="textlabel"
                           style={{backgroundColor: backgroundcolor}}>{label}</label>
                </div>
            )
        case 'datetime':
            return (
                <div hidden={hidden} className="input-wrapper">
                    <DateTimePicker
                        onChange={(value: any) =>
                            handleAction(new Date(Date.parse(value !== null ? value.toString() : "")))}
                        value={value}
                        clearIcon={null}
                        calendarAriaLabel={"aria-label"}
                        calendarClassName={"calendar"}
                        format={format}
                        disableClock={true}
                        showLeadingZeros={true}
                        openWidgetsOnFocus={false}
                        id={id}
                        locale={"en"}
                    />
                    <label htmlFor={label} className="textlabel"
                           style={{backgroundColor: backgroundcolor}}>{label}</label>
                </div>
            )
        case 'checkbox':
            return (
                <div hidden={hidden} className="">
                    <input
                        ref={ref}
                        id={id}
                        className={`form-check-input checkbox-custom ${className}`}
                        // className={`${className}`}
                        type="checkbox"
                        checked={value}
                        onChange={(e) => handleAction(e.target.checked, index)}
                        disabled={disable}
                        onKeyUp={(e) => {
                            if (handleKeyAction) {
                                handleKeyAction(e.key)
                            }
                        }}
                    />
                    {/*<span className="checkmark"></span>*/}
                    { (label?.length! > 0) && <label htmlFor={label} className="checklabel"
                           style={{backgroundColor: backgroundcolor}}>{label}</label>}
                </div>
            )
        case 'textarea':
            return (
                <div hidden={hidden} className="input-wrapper">
                          <textarea
                              ref={setRefs}
                              id={id}
                              className={`inputtext ${className}`}
                              style={{width: "100%", resize: "none"}}
                              value={value}
                              onChange={(e) => handleTextArea(e, index)}
                              placeholder={placeholder}
                              disabled={disable}
                              rows={1}
                          />
                    <label htmlFor={label} className="textlabel"
                           style={{backgroundColor: backgroundcolor}}>{label}</label>
                </div>

            )
        default:
            return (
                <div></div>
            )
    }

})

class InputSelectFieldProps {
    value: any;
    placeholder?: string;
    label?: string = '';
    handleAction: any;
    index?: number;
    error?: boolean;
    handleKeyAction?: any;
    // ref?: MutableRefObject<any>;
    disable?: boolean = false;
    hidden?: boolean = false;
    type: InputSelectType;
    isClearable?: boolean = false;
    isSearchable?: boolean = false;
    optionsArray?: any[] = [];
    backgroundcolor?: string;
    isMulti?: boolean = false;
    format?: string = "yyyy-MM-dd HH:mm:ss";
    className?: string = "";
    id?: string;
}

const dataOptions = (optionsArray?: Array<any>) => {
    let arr: Array<any>;
    if (optionsArray && optionsArray.length > 0) {
        arr = [...optionsArray]
    } else {
        return []
    }
    return arr.map((el) => {
        if (typeof el === "string") {
            return {value: el, label: el};
        } else if (typeof el === "number") {
            return {value: el, label: el + ""};
        } else if (el && el.id && el.name) {
            return {value: el.id, label: (el.alias && el.alias !== "") ? el.alias : el.name};
        }
        return {value: 0, label: "Error", color: "#8c8c8c", isFixed: true};
    })
}

export class DataOptionsUnit {
    value: string | number = "";
    label: string = "";
    color: string = "#000000";
    isFixed: boolean = true;
}


export enum InputSelectType {
    input = "input",
    password = "password",
    number = "number",
    select = "select",
    datetime = "datetime",
    checkbox = "checkbox",
    textarea = "textarea",
}