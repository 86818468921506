import {DataOptionsUnit, InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {CustomFieldType, TaskList} from "../../model/TaskList";
import {UserBasic} from "../../model/UserBasic";
import {TaskListShort} from "../../model/TaskListShort";
import {TaskListUserSettingsItem} from "../../model/TaskListUserSettings";
import {motion} from "framer-motion";
import {SpringAnimation} from "./TaskAnimations";

export const TaskFiltersComp = (props: TaskFiltersCompProps) => {

    const {
        taskListFilter, selectedTaskList, filterRef, modifyFilter,
        customFilter, responsible, getTasks, handleCustomFilter, taskLists,
        resetFilters, taskListUserSettings, allUsers
    } = props;

    /* Local states */


    const handleKeyUp = (escAction?: Function) => (key: string) => {
        if (key === "Enter") {
            getTasks();
        }
        if (key === "Escape") {
            if (escAction) escAction();
        }
    }

    /* Rendering */
    return (
        <div ref={filterRef} className="">
            <div className="border-gray">
                <div className="fw-bold center-horizontally">
                    Filters
                </div>
                <div className="center-horizontally">
                    <motion.div
                        {...SpringAnimation}
                        className="d-flex overflow-x-visible shrink-container column-gap-2 align-items-baseline center-horizontally pb-3">

                        {/* Keyword filter */}
                        <div key={101} style={{minWidth: "120px", width: "min-content"}} className="shrink-child">
                            <InputSelectField
                                value={taskListFilter.has('keyword') ? taskListFilter.get('keyword') : ""}
                                handleAction={(value: string) => {
                                    modifyFilter('keyword', value !== "" ? value : null)
                                }}
                                type={InputSelectType.input}
                                placeholder={"Keyword..."}
                                label="Keyword"
                                isClearable={true}
                                handleKeyAction={handleKeyUp(() => modifyFilter('keyword', null))}
                            />
                        </div>

                        {/* Responsible filter */}
                        <div key={102} style={{minWidth: "160px"}} className="shrink-child-flex">
                            <InputSelectField
                                value={responsible ? {
                                    label: responsible.alias ? responsible.alias : responsible.name,
                                    value: ""
                                } : ""}
                                optionsArray={selectedTaskList ? selectedTaskList.participants : allUsers}
                                handleAction={(item: DataOptionsUnit | null) => {
                                    modifyFilter('responsible', item ? item.value.toString() : null)
                                }}
                                type={InputSelectType.select}
                                label="Responsible"
                                placeholder="Select user..."
                                isClearable={true}
                            />
                        </div>

                        {/* Priority filter */}
                        <div key={103} style={{width: "100px"}} className="shrink-child-flex">
                            <InputSelectField
                                value={taskListFilter.has('priority') ? {
                                    label: taskListFilter.get('priority'),
                                    value: ""
                                } : ""}
                                optionsArray={[1, 2, 3, 4, 5]}
                                handleAction={(item: DataOptionsUnit | null) => {
                                    modifyFilter('priority', item ? item.value.toString() : null)
                                }}
                                type={InputSelectType.select}
                                placeholder={"Select priority..."}
                                label="Priority"
                                isClearable={true}
                            />
                        </div>

                        {/* Custom field filters */}
                        {selectedTaskList?.customFields?.map((item, index) => {
                            if (!item.filter || !taskListUserSettings.settings.get(item.name)?.visible) return null;
                            return (
                                <div key={index} className="shrink-child-flex mt-auto">
                                    {(item.type === CustomFieldType.list) &&
                                        <InputSelectField
                                            value={customFilter.has(item.name) ? {
                                                    label: customFilter.get(item.name),
                                                    value: customFilter.get(item.name)
                                                } :
                                             null
                                            }
                                            handleAction={(value: any) => {
                                                handleCustomFilter(item.name, value ? value.value : "");
                                            }}
                                            type={InputSelectType.select}
                                            optionsArray={
                                                selectedTaskList && selectedTaskList.listParameters &&
                                                selectedTaskList.listParameters.find(list => list.name === item.list) ?
                                                    // @ts-ignore
                                                    selectedTaskList.listParameters
                                                        .find(list => list.name === item.list).list
                                                    : undefined
                                            }
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder={"Select " + item.name.toLowerCase() + "..."}
                                            label={item.name}
                                        />
                                    }
                                    {(item.type === "value") &&
                                        <InputSelectField
                                            value={customFilter.has(item.name) ? customFilter.get(item.name) : ""}
                                            handleAction={(value: any) => handleCustomFilter(item.name, value)}
                                            type={InputSelectType.input}
                                            label={item.name}
                                            handleKeyAction={handleKeyUp}
                                            isClearable={true}
                                        />
                                    }
                                </div>

                            )
                        })}
                    </motion.div>
                </div>
                {/*<div className="center-horizontally column-gap-2">*/}
                {/*    <Button className="but but-primary but-sm mt-2" onClick={getTasks}>*/}
                {/*        Apply*/}
                {/*    </Button>*/}
                {/*    <Button className="but but-warning but-sm mt-2" onClick={resetFilters}>*/}
                {/*        Reset*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </div>
            {/*<Button onClick={() => console.log(selectedTaskList?.customFields)}>Test</Button>*/}
        </div>
    )
}

class TaskFiltersCompProps {
    selectedTaskList: TaskList | null | undefined;
    customFilter: Map<string, string>;
    taskListFilter: Map<string, string | null>;
    responsible: UserBasic | null | undefined;
    getTasks: Function;
    modifyFilter: Function;
    handleCustomFilter: Function;
    filterRef?: React.Ref<any>;
    resetFilters: Function;
    taskLists: TaskListShort[];
    taskListUserSettings: TaskListUserSettingsItem;
    allUsers: UserBasic[];
}