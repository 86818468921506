import "../styles.scss"
import {useHttp} from "../../hooks/http.hook";
import {beurl} from "../../constants/Constants";
import React, {useEffect, useRef, useState} from "react";
import {DataOptionsUnit, InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {useDispatch, useSelector} from "react-redux";
import {TaskFilter, taskFilterSlice, userSettingsSlice, userSlice} from "../../reducers";
import {TaskListShort} from "../../model/TaskListShort";
import {UserBasic} from "../../model/UserBasic";
import {PageHeader} from "../PageHeader";
import {defaultColumnSettings, TaskColumnSettings} from "./TaskColumnSettings";
import {
    TaskListUserSettingsGroup,
    TaskListUserSettingsItem,
    TaskListUserSettingsType
} from "../../model/TaskListUserSettings";
import {useJSON} from "../../hooks/json.hook";
import {TaskList} from "../../model/TaskList";
import {customFieldsInUserSettings} from "./TaskCustomFields";
import {TasksTableComp} from "./TasksTableComp";
import {TaskColumnsSettingsComp} from "./TaskColumnsSettingsComp";
import {TaskFiltersComp} from "./TaskFiltersComp";
import {UserSettings} from "../../model/UserSettings";
import {checkAuthentication, logout} from "../../services/UserService";
import {AnimatePresence, motion} from "framer-motion";
import {ShrinkAnimation} from "./TaskAnimations";
import {useNavigate} from "react-router-dom";
import {Button} from "../../forms/Button";

export const TaskComp = () => {

    /* Constants */
    const taskUrl = beurl(window.location.host.split(':')[0]) + "api/task";
    const request = useHttp();
    const {mapToJSON, mapFromJSON} = useJSON();

    /* Redux */
    const dispatch = useDispatch();
    const {addSetting, removeSetting} = userSettingsSlice.actions;
    const {addTaskFilter, removeTaskFilter} = taskFilterSlice.actions;
    const {taskListFilter} = useSelector<any>(state => state.taskfilter) as TaskFilter;
    const userSettings = useSelector<any>(state => state.usersettings) as UserSettings;
    const loggedInUser = useSelector<any>(state => state.user.user) as UserBasic;
    const {removeUser} = userSlice.actions;

    /* States */
    const [taskLists, setTaskLists] = useState<TaskListShort[]>([]);
    const [responsible, setResponsible] = useState<UserBasic | null | undefined>(null)
    const [selectedTaskList, setSelectedTaskList] =
        useState<TaskList | null | undefined>(null);
    // const [onTaskListChange, setOnTaskListChange] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [showColumnsVisibility, setShowColumnsVisibility] = useState(false);
    const [showAllDescriptions, setShowAllDescription] = useState(false);
    const [taskListUserSettings, setTaskListUserSettings] =
        useState<TaskListUserSettingsItem>({
            type: TaskListUserSettingsType.landscape,
            settings: new Map<string, TaskColumnSettings>()
        });
    const [taskListUserSettingsGroup, setTaskListUserSettingsGroup] =
        useState<TaskListUserSettingsGroup>({
            id: null,
            taskListId: selectedTaskList?.id,
            type: TaskListUserSettingsType.group,
            items: []
        });
    const [customFilter, setCustomFilter] = useState(new Map<string, string>());
    const [filterReset, setFilterReset] = useState(false);
    const [allUsers, setAllUsers] = useState<UserBasic[]>([]);
    const [localTasklistFilter, setLocalTasklistFilter] = useState<Map<string, string | null> | null>(null);
    const [addNewTask, setAddNewTask] = useState(false);
    const [getTasks, setGetTasks] = useState(false);
    const [initTaskListSettings, setInitTaskListSettings] = useState(false);
    const [portrait, setPortrait] = useState<boolean>(false); // page orientation portrait or landscape
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [showCheckBoxes, setShowCheckBoxes] = useState(false);
    const [myTasks, setMyTasks] = useState(false);

    /* References */
    const filterRef = useRef<HTMLDivElement>(null);
    const hasMounted1 = useRef(false);
    const hasMounted2 = useRef(false);
    const hasMounted3 = useRef(false);
    const hasMounted4 = useRef(false);

    const navigate = useNavigate();

    /* Hooks */
    useEffect(() => {
        getTaskLists();

        /* To load tasks when coming from another component */
        if (taskListFilter && taskListFilter.size > 0) {
            selectTaskList();
            setLocalTasklistFilter(taskListFilter);
        } else {
            setLocalTasklistFilter(new Map().set("filter", ""));
        }

        /* This is required to load tasks, when no task list selected at init */
        const timer = setTimeout(() => {
            setInitTaskListSettings(true);
        }, 500);

        window.addEventListener('resize', handleResize);

        if (size.height > size.width) {
            setPortrait(true);
        }

        // Clean up the timer
        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', handleResize);
        };

    }, [])

    useEffect(() => {
        if (taskListFilter.has('responsible')) {
            if (taskListFilter.get('responsible') === loggedInUser.id?.toString()) {
                setMyTasks(true);
            }
        }
        if (hasMounted1.current) {
            if (taskListFilter) {
                selectTaskList();
                selectResponsible();
            }
            // console.log(taskListFilter);
            setLocalTasklistFilter(taskListFilter);

            const settings = new Map(userSettings.settings);
            settings.set("filters", mapToJSON("", taskListFilter));
            dispatch(addSetting({key: "filters", value: mapToJSON("", taskListFilter)}));

        } else {
            hasMounted1.current = true;
        }
    }, [taskListFilter])

    useEffect(() => {
        if (hasMounted2.current) {
            const users = taskLists.map(taskList => taskList.participants).flat();
            const uniqueUsers = Array.from(new Set(users.map(user => user.id)))
                .map(id => {
                    return users.find(user => user.id === id) as UserBasic;
                });
            // console.log();
            setAllUsers(uniqueUsers);
        } else {
            hasMounted2.current = true;
        }
    }, [taskLists]);


    useEffect(() => {
        /* allUsers can be unavailable on page load with existing global filter, so selectResponsible fails at that moment */
        if (hasMounted3.current) {
            selectResponsible();
        } else {
            hasMounted3.current = true;
        }
    }, [allUsers]);

    useEffect(() => {
        if (hasMounted4.current) {
            setCustomFilter(new Map<string, string>());
            const taskList = new TaskListShort();
            if (selectedTaskList && selectedTaskList.id) {
                taskList.id = selectedTaskList.id!;
                taskList.name = selectedTaskList.name;
                taskList.participants = selectedTaskList.participants;
                /* Set task list for a new task to be created */
                // setNewTask({...newTask, taskList});
                loadSettings(selectedTaskList.id);
            } else {
                const usrSettings = {
                    type: TaskListUserSettingsType.landscape,
                    settings: new Map<string, TaskColumnSettings>()
                };
                usrSettings.settings = defaultColumnSettings();
                setTaskListUserSettings(usrSettings);
            }
        } else {
            hasMounted4.current = true;
        }
    }, [selectedTaskList]);


    useEffect(() => {
        if (initTaskListSettings) {
            if (taskListUserSettings.settings.size === 0) {
                const usrSettings = {
                    type: TaskListUserSettingsType.landscape,
                    settings: new Map<string, TaskColumnSettings>()
                };
                usrSettings.settings = defaultColumnSettings();
                setTaskListUserSettings(usrSettings);
            }
        }
    }, [initTaskListSettings]);

    useEffect(() => {
        if (portrait) {
            const portraitSettings = taskListUserSettingsGroup.items.find(item =>
                item.type === TaskListUserSettingsType.portrait);
            if (portraitSettings) {
                setTaskListUserSettings(portraitSettings);
            } else {
                const landscapeSettings = taskListUserSettingsGroup.items.find(item =>
                    item.type === TaskListUserSettingsType.landscape);
                if (landscapeSettings) {
                    setTaskListUserSettings(landscapeSettings);
                }
            }
        } else {
            const landscapeSettings = taskListUserSettingsGroup.items.find(item =>
                item.type === TaskListUserSettingsType.landscape);
            if (landscapeSettings) {
                setTaskListUserSettings(landscapeSettings);
            } else {
                const portraitSettings = taskListUserSettingsGroup.items.find(item =>
                    item.type === TaskListUserSettingsType.portrait);
                if (portraitSettings) {
                    setTaskListUserSettings(portraitSettings);
                }
            }
        }
    }, [taskListUserSettingsGroup, portrait]);

    useEffect(() => {
        if (size.height > size.width) {
            setPortrait(true);
        } else {
            setPortrait(false);
        }
    }, [size]);

    useEffect(() => {
        if (!myTasks) {
            modifyFilter('responsible', null);
        }
        if (myTasks) {
            modifyFilter('responsible', (loggedInUser ? loggedInUser.id + "" : null));
        }
    }, [myTasks]);

    /* Get/set values from/to redux */
    const selectTaskList = () => {
        if (taskListFilter.has('tasklist')) {
            if ((taskListFilter.get('tasklist') !== selectedTaskList?.id?.toString())) {
                getSelectedTaskList(parseInt(taskListFilter.get('tasklist')!));
            }
        } else {
            setSelectedTaskList(null);
        }
    }

    const selectResponsible = () => {
        if (taskListFilter.has('responsible')) {
            setResponsible(allUsers.find(item => item.id === parseInt(taskListFilter.get('responsible')!)));
        } else {
            setResponsible(null);
        }
    }

    const modifyFilter = (key: string, value: string | null) => {
        if (value) {
            dispatch(addTaskFilter({key, value}));
        } else {
            dispatch(removeTaskFilter(key));
        }
    }

    const handleCompleted = (value: string) => {
        if (value === "completed") {
            modifyFilter("completed", "true");
            modifyFilter("showall", "");
        } else if (value === "showall") {
            modifyFilter("completed", "");
            modifyFilter("showall", "true");
        } else {
            modifyFilter("completed", "");
            modifyFilter("showall", "");
        }
    }

    const handleSelectedTaskList = (selection: string | null) => {
        modifyFilter("tasklist", selection);
        resetFilters();
    }

    /* Save / load user settings */
    const saveTaskListSettings = () => {
        if (selectedTaskList) {
            const tsUserSettings: TaskListUserSettingsGroup = {...taskListUserSettingsGroup};
            const index = tsUserSettings.items.findIndex((item) =>
                item.type === (portrait ? TaskListUserSettingsType.portrait : TaskListUserSettingsType.landscape))
            // for (let i = 0; i < 2; i++) {
            if (index >= 0) tsUserSettings.items.splice(index, 1);
            // }
            // console.log(index);
            tsUserSettings.items.push({
                ...taskListUserSettings,
                type: (portrait ? TaskListUserSettingsType.portrait : TaskListUserSettingsType.landscape)
            });
            tsUserSettings.taskListId = selectedTaskList.id
            request(taskUrl + "/settings", 'POST', JSON.stringify(tsUserSettings, mapToJSON))
                .catch((e) => {
                    console.error(e.message);
                    if (e.cause && e.cause === 403) {
                        logout();
                        navigate("/expired");
                    }
                })
        }
    }

    const loadSettings = (taskListId: number) => {
        request(taskUrl + "/settings/" + taskListId)
            .then((data) => {
                // console.log(data);
                // const userSettings =
                // {
                //     id: data.id,
                //         taskListId: data.taskListId,
                //     settings: JSON.parse(data.settings, mapFromJSON)
                // };
                // console.log("Load settings");
                // const type = data.type.toString().replaceAll("\"", "");
                // console.log(data);
                const userSettingsGroup =
                    data.type ?
                        data.type.toString().replaceAll("\"", "") === TaskListUserSettingsType.group ?
                            {
                                id: data.id,
                                taskListId: data.taskListId,
                                type: TaskListUserSettingsType.group,
                                items: JSON.parse(data.items, mapFromJSON)
                                // settings: JSON.parse(data.items[0].settings, mapToJSON)
                            } :
                            {
                                id: null,
                                taskListId: null,
                                type: TaskListUserSettingsType.group,
                                items: []
                            } :
                        /* Read old type task list settings */
                        {
                            id: data.id,
                            type: TaskListUserSettingsType.group,
                            taskListId: data.taskListId,
                            items: [{
                                type: TaskListUserSettingsType.landscape,
                                settings: JSON.parse(data.items, mapFromJSON)
                            }]
                        };
                // console.log("Load settings");
                // console.log(userSettingsGroup);
                // const {usrSettings}
                //     = customFieldsInUserSettings(userSettings, selectedTaskList);
                setTaskListUserSettingsGroup(userSettingsGroup);
                // const {usrSettings}
                //     = customFieldsInUserSettings(userSettings, selectedTaskList);
                // setTaskListUserSettings(usrSettings);
            })
            .catch((e: Error) => {
                if (e.cause && e.cause !== 400) {
                    console.error(e);
                }
                const {usrSettings}
                    = customFieldsInUserSettings(
                    {type: TaskListUserSettingsType.landscape, settings: defaultColumnSettings()},
                    selectedTaskList);
                setTaskListUserSettings(usrSettings);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
    }

    const deleteSettings = () => {
        request(taskUrl + "/settings/" + selectedTaskList?.id, 'DELETE')
            .catch((e) => {
                console.error(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            })
            .finally(() => {
                loadSettings(selectedTaskList?.id!);
            })
    }

    const handleResize = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    /* Fetch Tasks Lists */
    const getTaskLists = () => {
        // console.log('Get task lists');
        request(taskUrl + "/tasklistshort")
            .then(data => setTaskLists(data))
            .catch((e) => {
                console.error(e.message);
                if (e.cause && e.cause === 403) {
                    logout();
                    navigate("/expired");
                }
            });
    }

    /* Fetch selected task list */
    const getSelectedTaskList = (id: number) => {
        request(taskUrl + "/tasklist/" + id)
            .then(data => {
                setSelectedTaskList({
                    ...data,
                    customFields: JSON.parse(data.customFields),
                    listParameters: JSON.parse(data.listParameters)
                });
            })
            .catch((e) => {
                    console.error(e.message);
                    if (e.cause && e.cause === 403) {
                        logout();
                        navigate("/expired");
                    }
                }
            );
    }

    /* Handlers */

    const handleTaskListUserSettings = (key: string, property: string, value: any) => {
        // console.log(`Key is ${key}, property is ${property}, value is ${value}.`);
        const userSettings = {...taskListUserSettings};
        const columnSettingsMap: Map<string, TaskColumnSettings> = {...taskListUserSettings}.settings;
        const columnSettings = columnSettingsMap.get(key);
        if (columnSettings) {
            columnSettings[property] = value;
            columnSettingsMap.set(key, columnSettings)
        }
        userSettings.settings = columnSettingsMap;
        setTaskListUserSettings(userSettings);
    }

    const handleCustomFilter = (key: string, value: string) => {
        const cstFlt = new Map(customFilter);
        // console.log(`Key is ${key} and value is ${value}.`);
        cstFlt.set(key, value);
        setCustomFilter(cstFlt);
    }

    const resetFilters = () => {
        setResponsible(null);
        setMyTasks(false);
        const filters = new Map(taskListFilter);
        filters.forEach((value, key) => {
            if (key !== "tasklist" && key !== "completed" && key !== "showall") {
                // console.log(`Remove filter ${key}.`);
                modifyFilter(key, null);
            }
        })
        setCustomFilter(new Map<string, string>());
        setFilterReset(!filterReset);
    }

    /* Handle filters visibility */
    useEffect(() => {
        if (!showFilters) {
            switchFiltersOverflow();
        }
    }, [showFilters]);

    const switchFiltersOverflow = () => {
        if (filterRef && filterRef.current) {
            if (showFilters) {
                filterRef.current.style.overflow = "visible";
            } else {
                filterRef.current.style.overflow = "hidden";
            }
        }

    }

    const initAddNewTask = () => {
        checkAuthentication()
            .then((_) => {
                setAddNewTask(!addNewTask);
            })
            .catch(e => {
                logout();
                navigate("/expired");
            })
    }

    /* Rendering of the main element */
    return (
        <div className="fade-in">
            <PageHeader title={"Tasks"}
                        buttonUsed={true}
                        onClick={() => initAddNewTask()}
                // onClick={() => setAddNewTask(!addNewTask)}
                        disabled={!selectedTaskList}
                        onRefresh={() => setGetTasks(!getTasks)}
                        refreshUsed={true}
            />

            {/* Task list filter */}
            <div className="mb-2">
                <InputSelectField
                    value={{value: selectedTaskList?.id, label: selectedTaskList?.name}}
                    handleAction={(item: DataOptionsUnit | null) => {
                        handleSelectedTaskList(item ? item.value.toString() : null)
                    }}
                    type={InputSelectType.select}
                    label="Tasklist"
                    optionsArray={taskLists}
                    placeholder="Task list not specified"
                    isClearable={true}
                />
                {/* Checkboxes */}
                <div className="d-flex">
                    <div className="overflow-scrol shrink-container mt-2">
                        <div className="shrink-child-2 center-vertically">
                            <label className="switch-selector">
                                <input
                                    type="checkbox"
                                    onChange={() => setShowFilters(!showFilters)}
                                    checked={showFilters}
                                />
                                <span className="slider round"></span>
                            </label>
                            <div className="check-label-1">Filters</div>
                        </div>
                        <div className="shrink-child-2 center-vertically">
                            <label className="switch-selector">
                                <input
                                    type="checkbox"
                                    onChange={() => setMyTasks(!myTasks)}
                                    checked={myTasks}
                                />
                                <span className="slider round"></span>
                            </label>
                            <div className="check-label-1">My tasks</div>
                        </div>
                        <div className="shrink-child-2 center-vertically gap-2">
                            <label className="switch-selector">
                                <input
                                    type="checkbox"
                                    onChange={() => setPortrait(!portrait)}
                                    checked={portrait}
                                />
                                <span className="slider round"></span>
                            </label>
                            <div className="d-flex center-vertically">
                                <svg className={`icon me-1 ${!portrait && "active"}`}
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <rect x="0" y="5" width="20" height="10" rx="2" ry="2"/>
                                </svg>
                                {/*<div>/</div>*/}
                                <svg className={`icon ${portrait && "active"}`}
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20">
                                    <rect x="5" y="0" width="10" height="20" rx="2" ry="2"/>
                                </svg>
                            </div>
                        </div>
                        <div className="shrink-child-2 center-vertically">
                            <label className="switch-selector">
                                <input
                                    type="checkbox"
                                    onChange={resetFilters}
                                    checked={((taskListFilter.size + customFilter.size - (taskListFilter.has('tasklist') ? 1 : 0)
                                        - (taskListFilter.has('completed') ? 1 : 0) - (taskListFilter.has('showall') ? 1 : 0)) > 0)}
                                />
                                <span className="slider round"></span>
                            </label>
                            <div className="check-label-1">Reset filters</div>
                        </div>
                        {/*{((taskListFilter.size + customFilter.size - (taskListFilter.has('tasklist') ? 1 : 0)*/}
                        {/*        - (taskListFilter.has('completed') ? 1 : 0) - (taskListFilter.has('showall') ? 1 : 0)) > 0) &&*/}
                        {/*    <div className="shrink-child-2 center-vertically">*/}
                        {/*        <div className="d-flex">*/}
                        {/*            <div className="filter-signal" onClick={resetFilters}></div>*/}
                        {/*            <div className="ms-1 filter-text">filters applied / reset</div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*}*/}
                    </div>
                    <div className="shrink-child-2 center-vertically">
                        <Button
                            className="but but-sm but-extralight"
                            onClick={() => setShowCheckBoxes(!showCheckBoxes)}
                            style={{scale: "0.75"}}
                        >
                            <i className={`fa ${showCheckBoxes ? "fa-chevron-up" : "fa-chevron-down"}`}/>
                        </Button>
                    </div>
                </div>
                <AnimatePresence>
                    {showCheckBoxes && <motion.div {...ShrinkAnimation}
                                className="show-checkboxes"
                                // style={showCheckBoxes ? {maxHeight: "100%"} : {}}
                    >
                        <div className="shrink-container">
                            <div className="shrink-child">
                                <InputSelectField
                                    value={showColumnsVisibility}
                                    handleAction={setShowColumnsVisibility}
                                    type={InputSelectType.checkbox}
                                    label="Column settings"
                                    className="checkbox-custom"
                                />
                            </div>
                            <div className="shrink-child">
                                <InputSelectField
                                    value={showAllDescriptions}
                                    handleAction={setShowAllDescription}
                                    type={InputSelectType.checkbox}
                                    label="All descriptions"
                                    className="checkbox-custom"
                                />
                            </div>
                            <div className="shrink-child">
                                <InputSelectField
                                    value={taskListFilter.has("showall") ? taskListFilter.get("showall") : false}
                                    handleAction={(value: boolean) => handleCompleted(value ? "showall" : "")}
                                    type={InputSelectType.checkbox}
                                    label="Include completed"
                                    className="checkbox-custom"
                                />
                            </div>
                            <div className="shrink-child">
                                <InputSelectField
                                    value={taskListFilter.has("completed") ? taskListFilter.get("completed") : false}
                                    handleAction={(value: boolean) => handleCompleted(value ? "completed" : "")}
                                    type={InputSelectType.checkbox}
                                    label="Completed only"
                                    className="checkbox-custom"
                                />
                            </div>
                        </div>
                    </motion.div> }
                </AnimatePresence>
            </div>

            <AnimatePresence>
                {showFilters &&
                    <motion.div
                        key={"filters"}
                        {...ShrinkAnimation}
                        ref={filterRef}
                        //* motion freezes values for animation time, so ShowFilters previous value is used -> inversion
                        onAnimationComplete={() => switchFiltersOverflow()}
                        style={{overflow: "hidden"}}
                    >
                        <TaskFiltersComp
                            selectedTaskList={selectedTaskList}
                            customFilter={customFilter}
                            taskListFilter={taskListFilter}
                            responsible={responsible}
                            getTasks={() => {
                            }}
                            modifyFilter={modifyFilter}
                            handleCustomFilter={handleCustomFilter}
                            resetFilters={resetFilters}
                            taskLists={taskLists}
                            taskListUserSettings={taskListUserSettings}
                            allUsers={allUsers}
                        />
                    </motion.div>
                }
                {showColumnsVisibility &&
                    <motion.div
                        key={"settings"}
                        {...ShrinkAnimation}
                        style={{overflow: "hidden"}}
                    >
                        <TaskColumnsSettingsComp
                            handleTaskListUserSettings={handleTaskListUserSettings}
                            saveSettings={saveTaskListSettings}
                            taskListUserSettings={taskListUserSettings}
                            setTaskListUserSettings={setTaskListUserSettings}
                            deleteSettings={deleteSettings}
                            portrait={portrait}
                            setPortrait={setPortrait}
                            taskListUserSettingsGroup={taskListUserSettingsGroup}
                            setTaskListUserSettingsGroup={setTaskListUserSettingsGroup}
                        />
                    </motion.div>
                }
            </AnimatePresence>


            <TasksTableComp
                taskListUserSettings={taskListUserSettings}
                showAllDescriptions={showAllDescriptions}
                selectedTaskList={selectedTaskList}
                taskListFilter={localTasklistFilter}
                customFilter={customFilter}
                externalGetTasks={getTasks}
                externalAddNewTask={addNewTask}
                userSettings={userSettings}
            />

            {/*<button onClick={() => document.body.classList.toggle('dark-mode')}>Test</button>*/}
            {/*<Button onClick={() => buttonAction()} className="but but-sm">Push me</Button>*/}
            {/*<Button onClick={() => buttonAction2()} className="but but-sm">Push me</Button>*/}

        </div>
    )
}

export class CustomFilter {
    filer: string;
    value: string;
}
